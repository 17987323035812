import type { Scale } from '@nib/types-session-api';
import { format } from 'date-fns';
import Cookie from 'js-cookie';
import { SESSION_COOKIE, SESSION_COOKIE_VERSION, PRICING_COOKIE, SESSION_AGENT_NAME } from '../constants';
import { isBrowser } from './utils';

export interface PricingCalcCookie {
  coverStartDate: string;
  coverEndDate: string;
  scale: Scale;
  hospitalProductId: number;
}

/**
 * Deprecating 'id' and 'version' for 'QuoteId' and 'Version' to follow ARHI cookie contract.
 * ARHI or ISHI cookies are differentiated by cookie name.
 * TODO: Clean up after about 90 days after merged to master.
 */
export interface SessionCookie {
  /** @deprecated */
  id?: string;
  /** @deprecated */
  version?: string;
  QuoteId: string;
  Version: string;
}

export type AgentNameCookie = string;

const encodeCookie = (value): string | null => {
  return isBrowser() ? window.btoa(JSON.stringify(value)) : null;
};

const decodeCookie = (cookie: string) => {
  return isBrowser() ? JSON.parse(window.atob(cookie)) : null;
};

const getSessionCookie = (): SessionCookie | Record<string, never> => {
  const cookie = Cookie.get(SESSION_COOKIE);
  if (cookie) {
    return decodeCookie(cookie);
  }
  return {};
};

export const getAgentNameCookie = (): string | void => {
  const cookie = Cookie.get(SESSION_AGENT_NAME);
  if (cookie) {
    return window.atob(cookie);
  }
  return '';
};

export const getPricingCookie = (): PricingCalcCookie | undefined => {
  const cookie = Cookie.get(PRICING_COOKIE);
  if (cookie) {
    const pricingCookie = decodeCookie(cookie);
    return {
      scale: pricingCookie.scale,
      coverStartDate: format(new Date(pricingCookie.coverStartDate), 'yyyy-MM-dd'),
      coverEndDate: format(new Date(pricingCookie.coverEndDate), 'yyyy-MM-dd'),
      hospitalProductId: Number(pricingCookie.hospitalProductId),
    };
  }

  return undefined;
};

export const getCookieSessionId = () => {
  const cookie = getSessionCookie();
  return cookie?.QuoteId || cookie?.id;
};

export const removeSessionCookie = () => {
  removeAgentNameCookie();
  return Cookie.remove(SESSION_COOKIE);
};

export const removeAgentNameCookie = () => {
  return Cookie.remove(SESSION_AGENT_NAME);
};

export const removePricingCookie = () => {
  return Cookie.remove(PRICING_COOKIE);
};

export const createSessionCookie = (sessionId: string, days = 90) => {
  const existingCookieData = getSessionCookie();
  let newCookie: SessionCookie;

  if (existingCookieData) {
    newCookie = {
      ...existingCookieData,
      QuoteId: sessionId,
      Version: SESSION_COOKIE_VERSION,
    };
  } else {
    newCookie = {
      QuoteId: sessionId,
      Version: SESSION_COOKIE_VERSION,
    };
  }

  Cookie.set(SESSION_COOKIE, encodeCookie(newCookie), { expires: days });
};

export const createPricingCookie = (params: PricingCalcCookie, days = 90) => {
  const { coverEndDate, coverStartDate, hospitalProductId, scale } = params;
  Cookie.set(PRICING_COOKIE, encodeCookie({ coverEndDate, coverStartDate, hospitalProductId, scale }), { expires: days });
};
