import { Scale } from '@nib/phi-constants';
import type { ISHISession } from '@nib/types-session-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUser } from '@sentry/gatsby';
import { navigate } from 'gatsby';
import qs from 'qs';
import { ISHIJoinState } from '..';
import { FamilyDetailsValues } from '../../components/Forms/FamilyDetails/types';
import { PersonalDetailsValues } from '../../components/Forms/PersonalDetails/types';
import { ISHIPageIndex, ISHIPageList } from '../../constants';
import { setCorrelationIdHeader } from '../../services/axiosConfig';
import { createSessionCookie, getCookieSessionId, getPricingCookie, removePricingCookie, removeSessionCookie } from '../../services/cookies';
import * as api from './api';
import { mapFamilyDetailsToSession } from './mappings/forms/familyDetails';
import { mapPersonalDetailsToSession } from './mappings/forms/personalDetails';
import { CurrentPage, mapActivePageToSession } from './mappings/funnelProgress';
import { getSession } from './selectors';
import { getScale } from './selectors/personalDetails';
import { parseInitialParams } from './utils';

/**
 * https://redux-toolkit.js.org/api/createAsyncThunk
 */

const saveCorrelationId = (sessionId: string) => {
  // 'user.id' is a searchable property in Sentry making it possible bind Sentry errors to possible Sumologic logs.
  setUser({ id: sessionId });
  setCorrelationIdHeader(sessionId);
};

export const initSession = createAsyncThunk<ISHISession>('session/init', async () => {
  const pricingParams = getPricingCookie();

  const sessionId = getCookieSessionId();

  if (sessionId) saveCorrelationId(sessionId);

  try {
    if (!pricingParams && !sessionId) {
      throw Error('There is not enough parameters to init a session');
    }

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const parsedParams = parseInitialParams(pricingParams || {}, queryParams, sessionId);
    const response = await api.init(parsedParams);
    const session = response.data;

    createSessionCookie(session.id ?? '');
    removePricingCookie();
    if (sessionId) saveCorrelationId(session.id ?? '');

    return session;
  } catch (error) {
    removeSessionCookie();
    throw error;
  }
});

export const saveSession = createAsyncThunk<ISHISession, ISHISession>('session/save', async (mappedSession) => {
  const response = await api.save(mappedSession);
  const session = response.data;
  return session;
});

export const updatePersonalDetails = createAsyncThunk<void, PersonalDetailsValues, { state: ISHIJoinState }>('session/personal-details/update', async (values, { getState, dispatch }) => {
  const mappedSession = mapPersonalDetailsToSession(values, getSession(getState()), false);
  await dispatch(saveSession(mappedSession)).unwrap();
});

export const submitPersonalDetails = createAsyncThunk<void, PersonalDetailsValues, { state: ISHIJoinState }>('session/personal-details/submit', async (values, { getState, dispatch }) => {
  const mappedSession = mapPersonalDetailsToSession(values, getSession(getState()), true);
  await dispatch(saveSession(mappedSession)).unwrap();
  const scale = getScale(getState());
  await navigate(scale === Scale.Single ? ISHIPageList[ISHIPageIndex.PAYMENT_DETAILS].link : ISHIPageList[ISHIPageIndex.FAMILY_DETAILS].link);
});

export const submitFamilyDetails = createAsyncThunk<void, FamilyDetailsValues, { state: ISHIJoinState }>('session/family-details/submit', async (values, { getState, dispatch }) => {
  const mappedSession = mapFamilyDetailsToSession(values, getSession(getState()), true);
  await dispatch(saveSession(mappedSession)).unwrap();
  await navigate(ISHIPageList[ISHIPageIndex.PAYMENT_DETAILS].link);
});

export const updateFamilyDetails = createAsyncThunk<void, FamilyDetailsValues, { state: ISHIJoinState }>('session/family-details/update', async (values, { getState, dispatch }) => {
  const mappedSession = mapFamilyDetailsToSession(values, getSession(getState()), false);
  await dispatch(saveSession(mappedSession)).unwrap();
});

export const updateCurrentPage = createAsyncThunk<void, CurrentPage, { state: ISHIJoinState }>('session/active-page/update', async (currentPage, { getState, dispatch }) => {
  const mappedSession = mapActivePageToSession(currentPage, getSession(getState()));
  await dispatch(saveSession(mappedSession)).unwrap();
});
