import type { ISHISession } from '@nib/types-session-api';
import { ISHIJoinState } from '../..';
import * as personalDetailsSelectors from './personalDetails';

export const selectData = (state: ISHIJoinState) => state.session;

export const getSession = (state: ISHIJoinState) => state.session.session || ({} as ISHISession);
export const getSessionFetched = (state: ISHIJoinState) => state.session.fetched;
export const getSessionError = (state: ISHIJoinState) => state.session.error;
export const getPersonalDetails = (state: ISHIJoinState) => personalDetailsSelectors.selectData(state);
