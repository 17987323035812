import { Scale, TScales } from '@nib/phi-constants';
import { ISHIPageIndex, ISHIPageList } from '../../constants';
import { ISHIFunnelPage, ISHIPage } from '../../types/custom';
import { ExtrasGraphicIcon, PriceGraphicIcon, CoupleGraphicIcon, FamilyGraphicIcon, SingleGraphicIcon, IconType } from '@nib/icons';
import { withPrefix } from 'gatsby';

export interface ProgressStep {
  title: string;
  isAccessed: boolean;
  isDisabled: boolean;
  isComplete: boolean;
  href: string;
  icon: IconType;
}

const LANDING_STEP: ProgressStep = {
  title: ISHIPageList[ISHIPageIndex.LANDING].title,
  isAccessed: true,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ISHIPageList[ISHIPageIndex.LANDING].link),
  icon: ExtrasGraphicIcon,
};

const PERSONAL_DETAILS_STEP: ProgressStep = {
  title: ISHIPageList[ISHIPageIndex.PERSONAL_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ISHIPageList[ISHIPageIndex.PERSONAL_DETAILS].link),
  icon: SingleGraphicIcon,
};

const COUPLE_STEP: ProgressStep = {
  title: ISHIPageList[ISHIPageIndex.PARTNER_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ISHIPageList[ISHIPageIndex.PARTNER_DETAILS].link),
  icon: CoupleGraphicIcon,
};

const FAMILY_STEP: ProgressStep = {
  title: ISHIPageList[ISHIPageIndex.FAMILY_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ISHIPageList[ISHIPageIndex.FAMILY_DETAILS].link),
  icon: FamilyGraphicIcon,
};

const PAYMENT_STEP: ProgressStep = {
  title: ISHIPageList[ISHIPageIndex.PAYMENT_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ISHIPageList[ISHIPageIndex.PAYMENT_DETAILS].link),
  icon: PriceGraphicIcon,
};

export const clone = (obj: any) => Object.assign({}, obj);

export const getProgressStepsForFamilyScale = () => {
  return [clone(LANDING_STEP), clone(PERSONAL_DETAILS_STEP), clone(FAMILY_STEP), clone(PAYMENT_STEP)];
};

export const getProgressStepsForCoupleScale = () => {
  return [clone(LANDING_STEP), clone(PERSONAL_DETAILS_STEP), clone(COUPLE_STEP), clone(PAYMENT_STEP)];
};

export const getProgressStepsForSingleScale = () => {
  return [clone(LANDING_STEP), clone(PERSONAL_DETAILS_STEP), clone(PAYMENT_STEP)];
};

export interface ProgressStepsArguments {
  scale: TScales;
  currentPage: ISHIFunnelPage;
  lastCompletedPage: ISHIPage;
}

/**
 * Given the current scale, return the appropriate progress steps
 * @param scale
 * @param extrasRequired
 * @returns
 */
export const getProgressSteps = (scale?: TScales): ProgressStep[] => {
  switch (scale) {
    case Scale.Single:
      return getProgressStepsForSingleScale();
    case Scale.Couple:
      return getProgressStepsForCoupleScale();
    case Scale.Family:
      return getProgressStepsForFamilyScale();
    default:
      return getProgressStepsForSingleScale();
  }
};

export const enableStep = (step: ProgressStep) => {
  if (step) {
    step.isDisabled = false;
    step.isAccessed = true;
  }
};

export const disableStep = (step: ProgressStep) => {
  step.isDisabled = true;
  step.isAccessed = false;
};

/**
 * Given the current page and the last completed page, determine if the provided step should be marked as accessed
 */
export const shouldPageBeMarkedAccessed = (lastCompletedPageIndex: number, currentPageStepIndex: number, stepIndex: number) => {
  const lastCompletedPageIndexInclActive = lastCompletedPageIndex + 1;
  // Either a page is marked as accessed because the "LastCompletedPage" from the session indicates it or a dependancy was completed or
  // or in the absense of this, the current step index is a precursor page to the current page.
  return (lastCompletedPageIndexInclActive >= currentPageStepIndex && stepIndex <= lastCompletedPageIndexInclActive) || stepIndex <= currentPageStepIndex;
};

/**
 * Given the appropriate progress steps for the selected scale, impart the current context of the funnel (selected products, progress etc.) onto the steps
 * @param progressSteps
 * @param params
 */
export const transformProgressStepsForState = (progressSteps: ProgressStep[], params: ProgressStepsArguments): ProgressStep[] => {
  const { currentPage, lastCompletedPage } = params;
  const currentPageStepIndex = currentPage.stepOrder;
  const lastCompletedPageIndex = lastCompletedPage.stepOrder || 0;
  const structure = progressSteps.slice();
  for (let i = 0; i < structure.length; i++) {
    const step = structure[i];
    if (shouldPageBeMarkedAccessed(lastCompletedPageIndex, currentPageStepIndex, i)) {
      enableStep(step);
    } else {
      disableStep(step);
    }
  }
  return structure;
};
